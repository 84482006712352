
<body>
    <section>
        <div class="grid grid-rows-1 place-items-center gap-0">
            <img id="logo" class="col-span-1" src="../../../assets/logo1.png" alt="SchneiderAutomobile-logo">
        </div>
    </section>
    <!--Standorte-->
    <section>
        <div class="container">
            <div class="grid grid-cols-1 place-items-center">
                <div class="col-span-1" id="panel">Standorte</div>
            </div>
            <div class="grid grid-cols-2 place-items-center max-[1025px]:grid-cols-1">
                <div class="grid grid-cols-1 place-items-center">
                    <div class="standort col-span-1"><img class="standortbild" src="" alt=""><p class="standorttext">Standort A</p></div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                    </div>
                </div>
                <div class="grid grid-cols-1 place-items-center">
                    <div class="standort col-span-1"><img class="standortbild " src="" alt=""><p class="standorttext">Standort B</p></div>
                    <div class="grid grid-cols-2 place-items-center">
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                        <div class="col-span-1 mitarbeiter"><img class="mitarbeiterbild" src="" alt=""><p class="mitarbeitertext">Mitarbeiter A</p></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer>
        <div id="footer" class="grid grid-cols-1">
            <a href="#">Datenschutz</a>
        </div>
    </footer>
</body>
