import { Injectable, inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FirestoreLocationEntity } from "./interfaces";
import { Firestore, collectionData, collection } from "@angular/fire/firestore";


@Injectable()
export class FirestoreClientService {
  private firestore: Firestore;

  // TODO: find other thing, because of the initial nullable value
  locations: BehaviorSubject<FirestoreLocationEntity> = new BehaviorSubject(
    null
  );

  constructor() {
    try {
      this.firestore = inject(Firestore);
      this.initLocations();
    } catch (error: unknown) {
      console.error("Firestore initialization failed with error: ", error);
    }
  }

  private async initLocations(): Promise<void> {
    // TODO: cache with localstorage
    const locations = collectionData(collection(this.firestore, "locations"));
    locations.subscribe((documents) => {
      documents.forEach((document) =>
        this.locations.next(document as any as FirestoreLocationEntity)
      );
    });
  }
}