<body>
    <section>
        <div class="grid grid-rows-1 place-items-center gap-0">
            <img id="logo" class="col-span-1" src="../../../assets/logo1.png" alt="SchneiderAutomobile-logo">
        </div>
    </section>

    <section id="desktop">
        <div class="container">
            <div class="grid grid-cols-1 place-items-center">
            <div class="col-span-1" id="panel">Über Uns</div>
        </div>
        <div class="grid grid-cols-4 place-items-center max-[1280px]:grid-cols-3 max-[750px]:grid-cols-2 max-[530px]:grid-cols-1">
            <div class="chef col-span-1 col-start-2 max-[1280px]:col-start-1 max-[750px]:col-start-1 max-[530px]:col-start-auto">
                <img src="" alt="">
                <div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam unde ex nam fugiat, velit sunt</p>
                </div>
            </div>
            <div class="chef col-span-1 col-start-3 max-[1280px]:col-start-3 max-[750px]:col-start-2 max-[530px]:col-start-auto">
                <img src="" alt="">
                <div>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam unde ex nam fugiat, velit sunt</p>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-1 place-items-center">
            <div id="geschichte" class="col-span-1">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa voluptatum quis perferendis, architecto dolorum 
                    cumque animi natus illum impedit aspernatur iste facere expedita, molestias quibusdam ex, atque praesentium aut
                    repellat!</p><br><p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa labore totam architecto magnam nisi quisquam pariatur, ab, 
                    earum deserunt libero nemo eius? 
                    Natus, in fuga velit quasi quae impedit fugiat.</p>
            </div>
        </div>
    </div>
</section>
<footer>
    <div id="footer" class="grid grid-cols-1">
        <a href="#">Datenschutz</a>
    </div>
</footer>
</body>
