export const environment = {
  production: false,
  baseURL: "https://sa-management-api-service-konele3jga-oa.a.run.app",
  recaptchaSiteKey: '6LcEVjApAAAAAKHbNAEneG4jORRdvouTdWG-v7Y0',
  firebase: {
    apiKey: "AIzaSyAJliGUHJJ_7U2mTQwjIM2Tb0icpjf46r8",
    authDomain: "noble-hangar-402910.firebaseapp.com",
    projectId: "noble-hangar-402910",
    storageBucket: "noble-hangar-402910.appspot.com",
    messagingSenderId: "860672957122",
    appId: "1:860672957122:web:378b0dff7aaa2284abb95b",
    measurementId: "G-4MEP41YM5Z",
  },
};
