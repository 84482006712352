import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { ApiClientService } from '../../services';

@Component({
  selector: 'app-purchase',
  standalone: true,
  imports: [FormsModule, RecaptchaModule, CommonModule],
  templateUrl: './purchase.component.html',
  styleUrl: './purchase.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `<re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>`
})
export class PurchaseComponent {
  siteKey = environment.recaptchaSiteKey;
  firstName: string = '';
  lastName: string = '';
  streetNameAndHouseNumber: string = '';
  postalCode: string = '';
  city: string = '';
  emailAddress: string = '';
  carModel: string = '';
  carBrand: string = '';
  carYearModel: string = '';
  mileage: string = '';
  selectedFileName: string | undefined;
  characterLimitExceeded: boolean = false;
  fileName: string;
  blobImage: Blob;
  recaptchaResolved = false;
  characterlimit = false;
  capturetoken: string;
  formData: FormData = new FormData(); 

  checkCharacterLimit(value: string, maxLength: number): boolean {
    const isExceeded = value && value.length > maxLength;
    this.characterLimitExceeded = isExceeded;
    return isExceeded;
  }

  isFirstInvalidField(fieldName: string): boolean {
    const fieldOrder = ['firstName', 'lastName', 'streetNameAndHouseNumber', 'postalCode', 'city', 'carModel', 'carYearModel', 'carBrand', 'mileage' /* weitere Feldnamen... */];
    const index = fieldOrder.indexOf(fieldName);

    for (let i = 0; i < index; i++) {
      if (this.myForm.controls[fieldOrder[i]].invalid) {
        return false; // Ein früheres Feld ist ungültig
      }
    }

    return true; // Dieses Feld ist das erste ungültige Feld
  }

  onFileChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    const fileReader = new FileReader();
  
    fileReader.readAsArrayBuffer(event.target.files[0]);
    fileReader.onload = () => {
      this.fileName = event.target.files[0].name;
      console.log(this.fileName);
      this.blobImage = new Blob([fileReader.result]);
    };
  
    if (file) {
      this.selectedFileName = file.name; 
    } else {
      this.selectedFileName = undefined;
    }
  }
  
  resolved(event: any) {
    console.log("captcha event", event);
    this.capturetoken = event;
    this.recaptchaResolved = true;
    
  }

constructor(
  private readonly apiClientService: ApiClientService,
) {
}

formSubmittedSuccessfully = false;
@ViewChild('myForm')
myForm!: NgForm;
isSubmitting = false;
async onSubmit(form: any) {
  if (form.valid && this.selectedFileName != null && this.recaptchaResolved) {
    const formData: FormData = new FormData();
    formData.append("fileName", this.fileName);
    formData.append("file", this.blobImage);
    formData.append("captchaToken", this.capturetoken)
    this.isSubmitting = true; 
    this.formSubmittedSuccessfully = true;
    Object.keys(form.value).forEach((key: string) =>
    formData.append(key, form.value[key])),
    await this.apiClientService.sendServiceRequest(formData);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } else {
    console.log("du kek sein");
    
  }
}
resetForm(form: any) {
  form.reset();
  this.formSubmittedSuccessfully = false;
  this.isSubmitting = false; // Button wieder aktivieren
}

}
