import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceComponent } from './service/service.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomeComponent } from './home/home.component';
import { LocationComponent } from './location/location.component';
import { PartnerComponent } from './partner/partner.component';
import { PurchaseComponent } from './purchase/purchase.component';

export const routes: Routes = [
    {
        path: "about-us",
        component: AboutUsComponent,
      },
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "location",
        component: LocationComponent,
      },
      {
        path: "partner",
        component: PartnerComponent,
      },
      {
        path: "purchase",
        component: PurchaseComponent,
      },
      {
        path: "service",
        component: ServiceComponent,
      },
      {
        path: "**",
        redirectTo: "/",
        pathMatch: "full",
      },
  ];
  
  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }