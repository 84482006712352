<body>
    <!--Logo und Gif-->
    <section>
      <div class="grid grid-rows-1 place-items-center gap-0">
          <img id="logo" class="col-span-1" src="../../../assets/logo1.png" alt="SchneiderAutomobile-logo">

      </div>
      <div id="gif"><video id="video" [autoplay]="true" [muted]="true" [loop]="true"><source src="../../assets/Black & Orange Simple Welcome To My Channel Youtube Intro.mp4" type="video/mp4">
        </video></div>
  </section>
  
    <!--Buttons 4 für weiterleitung weiteren Seiten-->
    <section>
        <div class="container">
        <div class="grid grid-cols-4 max-[1024px]:grid-cols-2 max-[425px]:grid-cols-2 place-items-center gap-0">
            <div (click)="router.navigate(['location'])" class="button font-extrabold" id="standorte">Standorte</div>
            <div (click)="router.navigate(['purchase'])" class="button font-extrabold" id="ankauf">Ankauf Occasion</div>
            <div (click)="router.navigate(['partner'])" class="button font-extrabold" id="partner">Partner</div>
            <div (click)="router.navigate(['about-us'])" class="button font-extrabold" id="ueberuns">Über Uns</div>
        </div>
        </div>
    </section>
    <!-- Service Anmeldung-->
    <section>
        <div class="container">
            <div class="grid grid-cols-1 place-items-center gap-0">
                 <button type="button" (click)="router.navigate(['service'])" class="font-extrabold" id="serviceAnmeldung">Service Anmeldung</button>
            </div>
        </div>
    </section>
    <!-- Unteren Bereich der Website-->
    <section>
        <div class="container" id="mobileformat">
            <div class="grid grid-col-1 place-items-center gap-0">
                <div id="occasionMobile" class="col-span-1 font-extrabold"><a href="#">Occasions Auto kaufen</a></div>
                <div id="bilderMobile" class="col-span-1"></div>
            </div>
        </div>
    </section>
    <footer>
      <div id="footer" class="grid grid-cols-1">
          <a href="#">Datenschutz</a>
      </div>
  </footer>
  </body>