
<body>
    <section>
        <div class="grid grid-rows-1 place-items-center gap-0">
            <img id="logo" class="col-span-1" src="../../../assets/logo1.png" alt="SchneiderAutomobile-logo">
        </div>
    </section>
    <section>
        <div class="container place-items-center">
            <div class="grid grid-cols-1 place-items-center">
                <div class="col-span-1" id="panel">Partner</div>
            </div>
            <div class="grid grid-cols-2 place-items-center max-[765px]:grid-cols-1">
                <div class="partner"><a href="https://discord.dgs4ops.ch/"><img class="partnerbild" src="../../../assets/Logo Schwarz_Weis.png" alt=""></a><br><p class="partnertext2">Entdecken Sie erstklassiges Hosting und maßgeschneiderte Auftragsentwicklung! Als Partner auf unserer Seite profitieren Sie von zuverlässigen Services und innovativen Lösungen für Ihre digitale Präsenz. Vertrauen Sie auf uns, um Ihre Online-Performance zu steigern!!</p></div>
                <div class="partner"><img class="partnerbild" src="" alt=""><p class="partnertext">Partner B</p></div>
            </div>
        </div>
    </section>
    <footer>
        <div id="footer" class="grid grid-cols-1">
            <a href="#">Datenschutz</a>
        </div>
    </footer>
</body>
