import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { ApiClientService, FirestoreClientService } from '../services';


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), ApiClientService, FirestoreClientService, importProvidersFrom(provideFirebaseApp(() => initializeApp({"projectId":"noble-hangar-402910","appId":"1:860672957122:web:378b0dff7aaa2284abb95b","storageBucket":"noble-hangar-402910.appspot.com","apiKey":"AIzaSyAJliGUHJJ_7U2mTQwjIM2Tb0icpjf46r8","authDomain":"noble-hangar-402910.firebaseapp.com","messagingSenderId":"860672957122","measurementId":"G-4MEP41YM5Z"}))), importProvidersFrom(provideFirestore(() => getFirestore()))]
};
