<body>
    <section>
        <div class="grid grid-cols-1 place-items-center">
            <img id="logo" class="col-span-1" src="../../assets/logo1.png" alt="Schneider-Automobile-logo">
        </div>
    </section>
    <section>
        <div class="container">
            <div class="grid place-items-center">
                <div class="col-span-1" id="panel">Service Anmeldung</div>
            </div>
            <div class="grid place-items-center">
                <form id="form" #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" class="">

                        <label for="firstName"></label>
                        <input [(ngModel)]="firstName" name="firstName" id="firstName" required placeholder="Vorname">
                        <div *ngIf="myForm.submitted && myForm.controls['firstName'].invalid" class="error">
                            First Name is required.
                        </div>
                         <div *ngIf="checkCharacterLimit(firstName, 20)" class="error">
                            Zeichen Limit von (20 Zeichen).
                        </div>
                        <label for="lastName"></label>
                            <input type="text" name="lastName" id="lastName" class="col-start-2" [(ngModel)]="lastName" required placeholder="Nachname">
                        <div *ngIf="myForm.submitted && myForm.controls['lastName'].invalid" class="error">
                            Last Name is required
                        </div>
                        <div *ngIf="checkCharacterLimit(lastName, 20)" class="error">
                            Zeichen Limit von (20 Zeichen).
                        </div>
                        
                        <label for="streetNameAndHouseNumber"></label>
                        <input type="text" name="streetNameAndHouseNumber" id="streetNameAndHouseNumber" [(ngModel)]="streetNameAndHouseNumber" required placeholder="Strasse, Hausnr.">
                        <div *ngIf="myForm.submitted && myForm.controls['streetNameAndHouseNumber'].invalid" class="error"> Street and Number is required</div>
                        <div *ngIf="checkCharacterLimit(streetNameAndHouseNumber, 50)" class="error">
                            Zeichen Limit von (50 Zeichen).
                        </div>

                        <label for="postalCode"></label>
                        <input type="text" name="postalCode" id="postalCode" [(ngModel)]="postalCode" required placeholder="PLZ">
                        <div *ngIf="myForm.submitted && myForm.controls['postalCode'].invalid" class="error"> PLZ is required</div>
                        <div *ngIf="checkCharacterLimit(postalCode, 8)" class="error">
                            Zeichen Limit von (8 Zeichen).
                        </div>

                        <label for="city"></label>
                        <input type="text" name="city" id="city" [(ngModel)]="city" required placeholder="Ort">
                        <div *ngIf="myForm.submitted && myForm.controls['city'].invalid" class="error"> City is required</div>
                        <div *ngIf="checkCharacterLimit(city, 40)" class="error">
                            Zeichen Limit von (40 Zeichen).
                        </div>
                        
                        <label for="emailAddress"></label>
                        <input type="email" name="emailAddress"  id="emailAddress" [(ngModel)]="emailAddress" #emailField="ngModel"  required  email placeholder="E-Mail">
                        <div class="error"
                        *ngIf="emailField.invalid && (emailField.dirty || emailField.touched || myForm.submitted)">
                        {{ emailField.errors?.['required'] ? 'E-Mail is required.' : emailField.errors?.['email'] ? 'Please enter a valid E-Mail.' : '' }}
                        </div>
                        <div *ngIf="checkCharacterLimit(emailAddress, 50)" class="error">
                            Zeichen Limit von (50 Zeichen).
                        </div>

                        <label for="location"></label>
                        <select ngModel name="location" id="location" required #selectOptionControl="ngModel">
                            <option value="" id="placeholderOption" disabled selected hidden>Service Standort</option>
                            <option *ngFor="let location of locations" [value]="location.location">{{ location.location }}</option>
                        </select>
                        <div *ngIf="selectOptionControl.invalid && (selectOptionControl.dirty || selectOptionControl.touched || myForm.submitted)" class="error">
                            Option auswählen ist erforderlich.
                        </div>
                        
                        <label for="fileInput" class="custom-file-label">{{ selectedFileName || 'Fahrzeugausweis' }}</label>
                        <input type="file" ngModul id="fileInput" name="fileInput" class="custom-file-input" (change)="onFileChange($event)" accept=".png, .jpg, .jpeg, .pdf" required>
                        <div *ngIf="!selectedFileName" class="error">
                            Datei auswählen
                        </div>

                        <label for="description"></label>
                        <textarea [(ngModel)]="textAreaValue" name="description" class="form-group" id="description" placeholder="Kurze Beschreibung vom Problem oder Service"></textarea>

                        <label for="promoCode"></label>
                        <input [(ngModel)]="promoCode" name="promoCode" id="promoCode" placeholder="PromoCode">
                        <div *ngIf="checkCharacterLimit(promoCode, 20)" class="error">
                            Zeichen Limit von (20 Zeichen).
                        </div>

                        <re-captcha (resolved)="resolved($event)" siteKey='6LcEVjApAAAAAKHbNAEneG4jORRdvouTdWG-v7Y0'></re-captcha>
                        <button id="Anmeldebutton" type="submit" [disabled]="isSubmitting" >Absenden</button>
                        <div *ngIf="formSubmittedSuccessfully" class="success-message place-self-center">
                        Formular wurde erfolgreich abgeschickt!
                    </div>
                </form>
            </div>
        </div>
    </section>
    <footer>
        <div id="footer" class="grid grid-cols-1">
            <a href="#">Datenschutz</a>
        </div>
    </footer>
</body>