import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild } from '@angular/core';
import { FormsModule, NgForm,} from '@angular/forms';
import { FirestoreLocationEntity } from '../../services/interfaces';
import { ApiClientService, FirestoreClientService } from '../../services';
import { environment } from '../../environments/environment';
import { RecaptchaModule } from 'ng-recaptcha';

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [FormsModule, CommonModule, RecaptchaModule],
  templateUrl: './service.component.html',
  styleUrl: './service.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `<re-captcha (resolved)="resolved($event)" [siteKey]="siteKey"></re-captcha>`
})
export class ServiceComponent {
  siteKey = environment.recaptchaSiteKey;
  firstName: string = '';
  lastName: string = '';
  streetNameAndHouseNumber: string = '';
  postalCode: string = '';
  city: string = '';
  emailAddress: string = '';
  promoCode: string = '';
  selectedFileName: string | undefined;
  location: any
  characterLimitExceeded: boolean = false;
  fileName: string;
  blobImage: Blob;
  textAreaValue: string = ''; 
  recaptchaResolved = false;
  formData: FormData = new FormData(); 

  checkCharacterLimit(value: string, maxLength: number) {
    if (value && value.length > maxLength) {
      return true;
    } else {
      return false;
    }
  }


onFileChange(event: any) {
  const inputElement = event.target as HTMLInputElement;
  const file = inputElement.files?.[0];
  const fileReader = new FileReader();

  fileReader.readAsArrayBuffer(event.target.files[0]);
  fileReader.onload = () => {
    this.fileName = event.target.files[0].name;
    console.log(this.fileName);
    this.blobImage = new Blob([fileReader.result]);
  };

  if (file) {
    this.selectedFileName = file.name; 
  } else {
    this.selectedFileName = undefined;
  }
}

addToFormData() {
  this.formData.append('textareaContent', this.textAreaValue);
}

capturetoken: string;

resolved(event: any) {
  console.log("captcha event", event);
  this.capturetoken = event;
  this.recaptchaResolved = true;
  
}

locations: FirestoreLocationEntity [] = [];
constructor(
  private readonly apiClientService: ApiClientService,
  private readonly firestoreClientService: FirestoreClientService,
) {
  this.firestoreClientService.locations.subscribe(
    (location: FirestoreLocationEntity) => {
      if (location != null) this.locations.push(location);
    }
  )
}

  formSubmittedSuccessfully = false;
  @ViewChild('myForm')
  myForm!: NgForm;
  isSubmitting = false;
  async onSubmit(form: any) {
    if (form.valid && this.selectedFileName != null && this.recaptchaResolved) {
      const formData: FormData = new FormData();
      formData.append("fileName", this.fileName);
      formData.append("file", this.blobImage);
      formData.append("captchaToken", this.capturetoken)
      this.isSubmitting = true; 
      this.formSubmittedSuccessfully = true;
      Object.keys(form.value).forEach((key: string) =>
      formData.append(key, form.value[key])),
      await this.apiClientService.sendServiceRequest(formData);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      console.log("du kek sein");
      
    }
  }

  resetForm(form: any) {
    form.reset();
    this.formSubmittedSuccessfully = false;
    this.isSubmitting = false; // Button wieder aktivieren
  }
}
