<body>
    <section>
        <div class="grid grid-cols-1 place-items-center">
            <img id="logo" class="col-span-1" src="../../assets/logo1.png" alt="Schneider-Automobile-logo">
        </div>
    </section>
    <section>
        <div class="container">
            <div class="grid place-items-center">
                <div class="col-span-1" id="panel">Service Anmeldung</div>
            </div>
            <div class="grid container place-items-center form">
                <form id="" #myForm="ngForm" (ngSubmit)="onSubmit(myForm)" class="">
                    <label for="firstName"></label>
                    <input [(ngModel)]="firstName" name="firstName" id="firstName" required placeholder="Vorname">
                    <div *ngIf="myForm.submitted && myForm.controls['firstName'].invalid && isFirstInvalidField('firstName')" class="error">
                        First Name is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(firstName, 20)" class="error">
                        Zeichen Limit von (20 Zeichen).
                    </div>

                    <label for="lastName"></label>
                    <input [(ngModel)]="lastName" name="lastName" id="lastName" required placeholder="Nachname">
                    <div *ngIf="myForm.submitted && myForm.controls['lastName'].invalid && isFirstInvalidField('lastName')" class="error">
                        Last Name is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(lastName, 20)" class="error">
                        Zeichen Limit von (20 Zeichen).
                    </div>

                    <label for="streetNameAndHouseNumber"></label>
                    <input [(ngModel)]="streetNameAndHouseNumber" name="streetNameAndHouseNumber" id="streetNameAndHouseNumber" required placeholder="Strasse, Hausnr.">
                    <div *ngIf="myForm.submitted && myForm.controls['streetNameAndHouseNumber'].invalid && isFirstInvalidField('streetNameAndHouseNumber')" class="error">
                        Street and Number is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(streetNameAndHouseNumber, 50)" class="error">
                        Zeichen Limit von (50 Zeichen).
                    </div>

                    <label for="postalCode"></label>
                    <input [(ngModel)]="postalCode" name="postalCode" id="postalCode" required placeholder="PLZ">
                    <div *ngIf="myForm.submitted && myForm.controls['postalCode'].invalid && isFirstInvalidField('postalCode')" class="error">
                        Postalcode is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(postalCode, 8)" class="error">
                        Zeichen Limit von (8 Zeichen).
                    </div>

                    <label for="city"></label>
                    <input [(ngModel)]="city" name="city" id="city" required placeholder="Ort">
                    <div *ngIf="myForm.submitted && myForm.controls['city'].invalid && isFirstInvalidField('city')" class="error">
                        City is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(city, 40)" class="error">
                        Zeichen Limit von (40 Zeichen).
                    </div>

                    <label for="emailAddress"></label>
                    <input type="email" name="emailAddress"  id="emailAddress" [(ngModel)]="emailAddress" #emailField="ngModel"  required  email placeholder="E-Mail">
                    <div class="error"
                    *ngIf="emailField.invalid && (emailField.dirty || emailField.touched || myForm.submitted)">
                    {{ emailField.errors?.['required'] ? 'E-Mail is required.' : emailField.errors?.['email'] ? 'Please enter a valid E-Mail.' : '' }}
                    </div>
                    <div *ngIf="checkCharacterLimit(emailAddress, 50)" class="error">
                        Zeichen Limit von (50 Zeichen).
                    </div>

                    
                    <label for="fileInput" class="custom-file-label">{{ selectedFileName || 'Fahrzeugausweis' }}</label>
                    <input type="file" ngModul id="fileInput" name="fileInput" class="custom-file-input" (change)="onFileChange($event)" accept=".png, .jpg, .jpeg, .pdf" required>
                    <div *ngIf="!selectedFileName" class="error">
                        Datei auswählen
                    </div>

                    <label for="carModel"></label>
                    <input [(ngModel)]="carModel" name="carModel" id="carModel" required placeholder="Fahrzeugmodell">
                    <div *ngIf="myForm.submitted && myForm.controls['carModel'].invalid && isFirstInvalidField('carModel')" class="error">
                        Carmodel is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(carModel, 40)" class="error">
                        Zeichen Limit von (40 Zeichen).
                    </div>

                    <label for="carBrand"></label>
                    <input [(ngModel)]="carBrand" name="carBrand" id="carBrand" required placeholder="Marke">
                    <div *ngIf="myForm.submitted && myForm.controls['carBrand'].invalid && isFirstInvalidField('carBrand')" class="error">
                        Car Brand is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(carBrand, 40)" class="error">
                        Zeichen Limit von (40 Zeichen).
                    </div>

                    <label for="carYearModel"></label>
                    <input [(ngModel)]="carYearModel" name="carYearModel" id="carYearModel" required placeholder="Baujahr">
                    <div *ngIf="myForm.submitted && myForm.controls['carYearModel'].invalid && isFirstInvalidField('carYearModel')" class="error">
                        Car Year Model is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(carYearModel, 40)" class="error">
                        Zeichen Limit von (40 Zeichen).
                    </div>

                    <label for="mileage"></label>
                    <input [(ngModel)]="mileage" name="mileage" id="mileage" required placeholder="Kilometerstand">
                    <div *ngIf="myForm.submitted && myForm.controls['mileage'].invalid && isFirstInvalidField('mileage')" class="error">
                        Mileage is required.
                      </div>
                    <div *ngIf="checkCharacterLimit(mileage, 40)" class="error">
                        Zeichen Limit von (40 Zeichen).
                    </div>

                    <re-captcha (resolved)="resolved($event)" siteKey='6LcEVjApAAAAAKHbNAEneG4jORRdvouTdWG-v7Y0'></re-captcha>
                    <button id="Anmeldebutton" type="submit" [disabled]="isSubmitting" >Absenden</button>
                    <div *ngIf="formSubmittedSuccessfully" class="success-message place-self-center">
                        Formular wurde erfolgreich abgeschickt!
                    </div> 
                </form>
            </div>
        </div>
    </section>
    <footer>
        <div id="footer" class="grid grid-cols-1">
            <a href="#">Datenschutz</a>
        </div>
    </footer>
</body>
