import { Injectable } from "@angular/core";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { environment } from "../environments/environment";

@Injectable()
export class ApiClientService {
    private axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: environment.baseURL,
        })
    }

    async sendServiceRequest(formData: FormData) {
        let response: AxiosResponse<any>;

        try {
            response = await this.axios.post("/api/service", formData, {
                headers: {
                    "Content-Type": `multipart/form-data;`,
                },
            })
        } catch (error: unknown) {
            console.error(error);
        }
    }
}